import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';

import { SentryService } from 'src/features/tracking';
import { showNotification } from 'src/helpers/notifications';

import { usePromotion } from './queries/subscription';
import { usePurchasePreview } from './usePurchasePreview';
import {
  getInitialPromoBillingPeriod,
  getPlanByRole,
  getPlanMaxLicenseQuantity,
  isStudentRole,
} from '../helpers';
import { Role, Plan, BillingPeriod, PlanSetup, Purchase, BillingAddress } from '../types';

interface Props {
  data: PlanSetup;
  disablePlanRelatedChanges?: boolean;
  minQuantity?: number;
  onSubmit?(params: {
    planSetup: PlanSetup;
    purchasePreview: Purchase;
    setErrors?(errors: Record<string, any>): void;
  }): void;
  email: string;
  promotionId?: string;
  billingAddress?: BillingAddress;
  studentVerificationId?: string;
}

export const usePlanDetailsForm = ({
  data,
  disablePlanRelatedChanges,
  minQuantity = 1,
  onSubmit,
  email,
  promotionId,
  billingAddress,
  studentVerificationId,
}: Props) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    setErrors: setFormikErrors,
    setValues,
  } = useFormik({
    initialValues: data,
    validateOnChange: false,
    onSubmit: (planSetup) => {
      if (!purchasePreview) return;
      onSubmit?.({ planSetup, purchasePreview, setErrors: setFormikErrors });
    },
  });
  const maxQuantity = getPlanMaxLicenseQuantity(values.plan);
  const overMaxQuantity = !disablePlanRelatedChanges && values.quantity > maxQuantity;
  const isStudent = useMemo(() => isStudentRole(values.role), [values.role]);

  const setErrors = useCallback(
    (errors: Record<string, string>) => {
      setFormikErrors(errors);

      const { email: emailError, axios: axiosError } = errors;

      if (emailError) {
        showNotification({ type: 'error', title: emailError });
      }
      if (axiosError) {
        showNotification({ type: 'error', title: axiosError });
      }
    },
    [setFormikErrors],
  );

  const { data: promotionData } = usePromotion();

  const {
    purchasePreview,
    purchasePreviewComponentProps,
    isDiscountLoading,
    isLoading: isPurchasePreviewLoading,
  } = usePurchasePreview({
    email,
    billingPeriod: values.billingPeriod,
    overMaxQuantity,
    role: values.role,
    plan: values.plan,
    quantity: values.quantity,
    discountCode: values.discountCode,
    setErrors,
    isTrial: false,
    promotionId,
    billingAddress,
    studentVerificationId: isStudentRole(values.role) ? studentVerificationId : undefined,
  });

  const setValue = (field: string) => (value: any) => {
    if (field === 'role' || field === 'plan') {
      setFieldValue('quantity', minQuantity);
    }
    setFieldValue(field, value);
  };

  const handleRoleChange = (value: Role) => {
    const newPlan = getPlanByRole(value, Plan.NORMAL);
    setValues({
      ...values,
      role: value,
      plan: newPlan,
      billingPeriod: BillingPeriod.ANNUAL,
      quantity: isStudentRole(value) ? 1 : minQuantity,
    });
  };

  const handlePlanChange = (value: Plan) => {
    setValue('plan')(value);

    try {
      const billingPeriod = promotionData
        ? getInitialPromoBillingPeriod(promotionData, value)
        : BillingPeriod.ANNUAL;
      setValue('billingPeriod')(billingPeriod);
    } catch (e) {
      SentryService.captureException(e);
    }
  };

  const handleBillingPeriodChange = (value: BillingPeriod) => {
    setValue('billingPeriod')(value);
  };

  return {
    values,
    setValue,
    handleRoleChange,
    handlePlanChange,
    handleBillingPeriodChange,
    purchasePreview,
    purchasePreviewComponentProps,
    isDiscountLoading,
    isPurchasePreviewLoading,
    errors: errors as typeof errors & { axios?: string },
    handleSubmit,
    setErrors,
    maxQuantity,
    overMaxQuantity,
    isStudent,
  };
};
