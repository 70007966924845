import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { signInAndSignOutMatcher } from 'src/features/auth/state';
import type { ApplicationState } from 'src/state';

import { HandoutsLanguageType, SelectedHandout } from '../types';

export interface HandoutsState {
  selectedHandouts: SelectedHandout[];
  singleHandoutLanguage: HandoutsLanguageType;
}
export const initialState: HandoutsState = {
  selectedHandouts: [],
  singleHandoutLanguage: HandoutsLanguageType.English,
};

const slice = createSlice({
  name: 'handouts',
  initialState,
  reducers: {
    selectHandout(state, action: PayloadAction<Omit<SelectedHandout, 'language'>>) {
      if (!state.selectedHandouts.find((item) => item.id === action.payload.id)) {
        // for each selected handout, we add a default language in case user won't change it
        const handoutWithDefaultLanguage = { ...action.payload, language: HandoutsLanguageType.English };
        state.selectedHandouts.push(handoutWithDefaultLanguage);
      }
    },
    deselectHandout(state, action: PayloadAction<string>) {
      const updatedSelected = state.selectedHandouts.filter((el) => el.id !== action.payload);
      state.selectedHandouts = updatedSelected;
    },
    setSelectedHandoutLanguage(state, action: PayloadAction<SelectedHandout>) {
      state.selectedHandouts = state.selectedHandouts.map((item) =>
        item.id === action.payload.id ? { ...item, language: action.payload.language } : item,
      );
    },
    clearHandoutsSelection(state) {
      state.selectedHandouts = [];
    },
    setSingleHandoutLanguage(state, action: PayloadAction<HandoutsLanguageType>) {
      state.singleHandoutLanguage = action.payload;
    },
    clearSingleHandoutLanguage(state) {
      state.singleHandoutLanguage = initialState.singleHandoutLanguage;
    },
  },
  extraReducers: (builder) => builder.addMatcher(signInAndSignOutMatcher, () => initialState),
});

export const {
  selectHandout,
  deselectHandout,
  setSelectedHandoutLanguage,
  clearHandoutsSelection,
  setSingleHandoutLanguage,
  clearSingleHandoutLanguage,
} = slice.actions;

const persistConfig: PersistConfig<HandoutsState> = {
  key: 'handouts',
  storage: AsyncStorage,
};

const handoutsReducer = persistReducer(persistConfig, slice.reducer);
const handoutsSelector = (state: ApplicationState) => state.handouts;

export { handoutsReducer, handoutsSelector };
