export enum TokenValidationError {
  NotFound = 'NotFound',
  Expired = 'Expired',
  Default = 'Default',
}

export enum FlaggedFeature {
  ClinicalBrief = 'clinical-brief',
  Algorithm = 'algorithm',
  PatientGuides = 'patient-guides',
  RedesignPhase1 = 'redesign-phase-1',
  RedesignPhase2 = 'redesign-phase-2',
  RedesignPhase3 = 'redesign-phase-3',
  RedesignPhase4 = 'redesign-phase-4',
  StudentRenewals = 'student-renewals',
  CoreFuncUXUpdates = 'core-func-ux-updates',
  DDx = 'differential-diagnosis',
}

export enum Permission {
  FullAccessBasicContentTypes = 'hasFullAccessToBasicContentTypes',
  DisplayClinicalBriefs = 'canDisplayClinicalBriefs',
  FullAccessClinicalBriefs = 'hasFullAccessToClinicalBriefs',
  DisplayDDx = 'canDisplayDDx',
  FullAccessDDx = 'hasFullAccessToDDx',
  AccessToStudentRenewals = 'hasAccessToStudentRenewals',
  DisplayAlgorithms = 'canDisplayAlgorithms',
  FullAccessAlgorithms = 'hasFullAccessToAlgorithms',
  DisplayClinicalHandouts = 'canDisplayClinicalHandouts',
  FullAccessClinicalHandouts = 'hasFullAccessToClinicalHandouts',
  ManageSubscription = 'canManageSubscription',
  ManagePlan = 'canManagePlan',
  AccessIndividualAccountFeatures = 'canAccessIndividualAccountFeatures',
  ManageGroup = 'canManageGroup',
  InvitingSupportStaff = 'hasSupportStaffFeatureEnabled',
  FullAccessDrugHandouts = 'hasFullAccessToDrugHandouts',
  AccessToEmailHandouts = 'hasAccessToEmailHandouts',
  CanSearchForUnavailableContentTypes = 'canSearchForUnavailableContentTypes',
  AccessToRedesignPhase4 = 'hasAccessToRedesignPhase4',
  fullAccessToCoreFuncUXUpdates = 'hasFullAccessToCoreFuncUXUpdates',
}

export enum GroupRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  SUPPORT_STAFF = 'support-staff',
}
