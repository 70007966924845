import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, View } from 'react-native';

import { ContentItemDetails } from 'src/constants/types';
import { AccordionRef } from 'src/constants/types/accordion';
import { QueryErrorBanner } from 'src/errorHandling/components/QueryErrorBanner';
import { RemoveNoteConfirmModal } from 'src/features/notes';
import { NotesListProvider, useNotesListContext } from 'src/features/notes/context/NotesListContext';
import {
  getButtonsPerSection,
  getSectionsWithPublicationDetails,
  isAlgorithm,
  isItemWithSections,
  markDownToPlainText,
  shouldItemHaveTableOfContents,
} from 'src/helpers';
import { useContainer, useDeviceInfo, useScrollListener } from 'src/hooks';
import { useAccordionState } from 'src/hooks/useAccordionState';
import { useContentItemVisit } from 'src/hooks/useContentItemVisit';
import { useSectionScrolling } from 'src/hooks/useSectionScrolling.web';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { i18n } from 'src/locale';
import { NavigationBlocker, type NavigationBlockerRef } from 'src/navigation/components/NavigationBlocker';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import {
  CONTAINER_PADDING_H_MOBILE,
  CONTAINER_PADDING_V_DESKTOP_LARGE,
  CONTAINER_PADDING_V_MOBILE,
  MOBILE_TOC_HEIGHT,
  TOC_DESKTOP_PADDING,
  TOC_DESKTOP_WIDTH,
  palette,
  typography,
} from 'src/styles';

import { ContentAccordion } from './ContentAccordion';
import { Props } from './ContentItemScreen';
import { Header } from './Header/Header';
import { TableOfContentsDesktop } from './TableOfContents/TableOfContentsDesktop';
import { TableOfContentsMobile } from './TableOfContents/TableOfContentsMobile';
import { FullScreenTemplate } from '../FullScreenTemplate';
import { MobileContentHeader } from '../MobileContentHeader';

interface ContentProps extends Props {
  item: ContentItemDetails;
}

type SectionsRefs = {
  [key: string]: RefObject<View>;
};

const scrollToTop = () => window.scrollTo({ top: 0 });

const Content: React.FC<ContentProps> = ({
  item,
  featureButtons,
  headerDescription,
  notes,
  sectionOpenByDefault,
  sectionToScrollTo,
  subsectionToScrollTo,
  oneSectionContent,
  footer,
  title,
  titleInUppercase,
}) => {
  const referenceDivRef = useRef<View>(null);
  const accordionRef = useRef<AccordionRef>(null);
  const visibleSections = useRef<Record<string, boolean>>({});
  const navigationBlockerRef = useRef<NavigationBlockerRef>(null);
  const tocRef = useRef<View>(null);
  const [isAnyItemExpanded, setIsAnyItemExpanded] = useState<boolean | undefined>(
    !accordionRef.current?.areAllItemsCollapsed(),
  );

  const [removeNoteConfirmModalProps, setRemoveNoteConfirmModal] = useState<null | React.ComponentProps<
    typeof RemoveNoteConfirmModal
  >>(null);

  const [activeSection, setActiveSection] = useState<string>();

  const { isLargeDesktop, isTablet, isHugeDesktop } = useDeviceInfo();
  const navigation = useNavigation();
  const { isAtTop } = useScrollListener({ topThreshold: 100 });
  const { topPanelHeight } = useWebAppLayout();
  const containerStyles = useContainer({ fullWidthOnMobile: true, maxWidth: 'narrow' });
  const { unsavedNotes } = useNotesListContext();
  useContentItemVisit();

  const sections = useMemo(() => getSectionsWithPublicationDetails(item), [item]);
  const accordionState = useAccordionState(sections);

  const isTOCAvailable = shouldItemHaveTableOfContents(item);
  const shouldDisplayMobileTOC = isTOCAvailable && !isLargeDesktop;
  const shouldDisplayDesktopTOC = isTOCAvailable && !shouldDisplayMobileTOC;

  // keeping the refs to all sections divs
  const sectionsRefs = useMemo(() => {
    const sections = isItemWithSections(item) ? item.sections : [];
    return sections.reduce<SectionsRefs>(
      (obj, section) => ({
        ...obj,
        [section.id]: React.createRef(),
      }),
      {},
    );
  }, [item]);

  const { scrollToSectionAndSubsection, openAndScrollToSection } = useSectionScrolling({
    accordionRef,
    item,
    referenceDivRef,
    sections,
    sectionsRefs,
  });

  /** Used to open and scroll to a section after clicking on TOC or Quick Link */
  const handleMenuItemPress = useCallback(
    (id: string) => {
      if (sectionToScrollTo === id) {
        openAndScrollToSection(id);
      }
      navigation.setParams({ section: id, subsection: undefined });
    },
    [openAndScrollToSection, navigation, sectionToScrollTo],
  );

  const onSectionUpdate = useCallback(() => {
    // this relies on object property insertion order
    const firstVisibleSection = Object.entries(visibleSections.current).find(
      ([sectionId, visible]) => visible && accordionRef.current?.isItemExpanded(sectionId),
    );

    if (firstVisibleSection === undefined) {
      setActiveSection(undefined);
      return;
    }

    const [firstVisibleSectionId] = firstVisibleSection;

    setActiveSection(firstVisibleSectionId);
  }, []);

  const onExpandedItemsChange = useCallback(() => {
    onSectionUpdate();
  }, [onSectionUpdate]);

  useEffect(() => {
    // detecting which sections are currently visible
    const tocHeight = shouldDisplayMobileTOC ? MOBILE_TOC_HEIGHT : 0;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = (entry.target as HTMLDivElement).dataset.sectionid;
          visibleSections.current[id!] = entry.isIntersecting;
        });
        onSectionUpdate();
      },
      {
        // Subtracting 1 so active section is detected more precisely. Probably caused by sections' borders
        rootMargin: `-${topPanelHeight + tocHeight + 1}px 0px 0px 0px`,
      },
    );
    Object.values(sectionsRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current as unknown as HTMLDivElement);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [
    sectionsRefs,
    isLargeDesktop,
    visibleSections,
    onSectionUpdate,
    topPanelHeight,
    shouldDisplayMobileTOC,
  ]);

  useEffect(() => {
    const subsection = subsectionToScrollTo?.toLowerCase();
    scrollToSectionAndSubsection(sectionToScrollTo, subsection);
  }, [scrollToSectionAndSubsection, sectionToScrollTo, subsectionToScrollTo]);

  useEffect(() => {
    if (sectionOpenByDefault) {
      accordionRef.current?.expandItem(sectionOpenByDefault);
    }
  }, [sectionOpenByDefault]);

  useLayoutEffect(() => {
    navigation.setOptions({ title: markDownToPlainText(item.title) });
  }, [item.title, navigation]);

  const sectionsWithButtons = getButtonsPerSection(featureButtons);

  const contentNameAsPageTitle = !isAtTop;
  const paddingVertical = isLargeDesktop ? CONTAINER_PADDING_V_DESKTOP_LARGE : CONTAINER_PADDING_V_MOBILE;
  const tocTop = topPanelHeight + paddingVertical;

  return (
    <FullScreenTemplate
      title={contentNameAsPageTitle ? item.title : title}
      titleInUppercase={titleInUppercase}
      titleStyle={contentNameAsPageTitle && styles.contentTitle}
      stickyContent={
        shouldDisplayMobileTOC && (
          <TableOfContentsMobile
            item={item}
            onItemPress={handleMenuItemPress}
            scrollToTop={scrollToTop}
            activeSectionId={activeSection}
          />
        )
      }
      showBackButton
    >
      <View style={[styles.wrapper, { paddingVertical }]} ref={referenceDivRef}>
        {shouldDisplayDesktopTOC && (
          <TableOfContentsDesktop
            item={item}
            onItemPress={handleMenuItemPress}
            scrollToTop={scrollToTop}
            activeSectionId={activeSection}
            top={tocTop}
            ref={tocRef}
            sections={sections}
            contentType={item.contentType}
            contentId={item.id}
            contentTitle={item.title}
            accordionRef={accordionRef}
            isAnyItemExpanded={isAnyItemExpanded}
            setRemoveNoteConfirmModal={setRemoveNoteConfirmModal}
          />
        )}
        <View
          style={[
            !shouldDisplayDesktopTOC && containerStyles,
            styles.content,
            shouldDisplayDesktopTOC && [styles.contentWithToc, isHugeDesktop && styles.contentWithTocHuge],
          ]}
        >
          <Header
            item={item}
            featureButtons={featureButtons}
            headerDescription={headerDescription}
            onQuickLinkPress={handleMenuItemPress}
          />
          {oneSectionContent ? (
            <View
              style={[styles.oneSectionContentWrapper, isTablet && styles.oneSectionContentWrapperTablet]}
            >
              {!isAlgorithm(item) && (
                <MobileContentHeader>{i18n.t('contents:contents')}</MobileContentHeader>
              )}

              {oneSectionContent}
            </View>
          ) : (
            <ContentAccordion
              state={accordionState}
              accordionRef={accordionRef}
              sections={sections}
              onExpandedItemsChange={onExpandedItemsChange}
              notes={notes}
              contentType={item.contentType}
              sectionsRefs={sectionsRefs}
              contentId={item.id}
              contentTitle={item.title}
              sectionsWithButtons={sectionsWithButtons}
              isAnyItemExpanded={isAnyItemExpanded}
              setIsAnyItemExpanded={setIsAnyItemExpanded}
              removeNoteConfirmModalProps={removeNoteConfirmModalProps}
              setRemoveNoteConfirmModal={setRemoveNoteConfirmModal}
            />
          )}
        </View>
      </View>
      {footer}
      <NavigationBlocker isBlocked={!!unsavedNotes.length} ref={navigationBlockerRef}>
        <RemoveNoteConfirmModal
          cancel={() => navigationBlockerRef.current?.cancelNavigation()}
          confirm={() => navigationBlockerRef.current?.confirmNavigation()}
          type="navigation"
        />
      </NavigationBlocker>
    </FullScreenTemplate>
  );
};

export const ContentItemScreen: React.FC<Props> = ({ item, ...props }) => {
  if (item) {
    return (
      <NotesListProvider>
        <Content item={item} {...props}></Content>
      </NotesListProvider>
    );
  }

  return (
    <FullScreenTemplate isLoading={props.fetchStatus === 'fetching'} displayStandbyText>
      <QueryErrorBanner isDataAvailable={!!item} error={props.error} fetchStatus={props.fetchStatus} />
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  oneSectionContentWrapper: {
    marginTop: 30,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  oneSectionContentWrapperTablet: {
    paddingHorizontal: 0,
  },
  contentTitle: {
    textTransform: 'none',
    ...typography.body3Bold,
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 0,
  },
  contentWithToc: {
    width: 800,
    paddingHorizontal: TOC_DESKTOP_PADDING,
    borderLeftWidth: 1,
    borderColor: palette.grey2,
  },
  contentWithTocHuge: {
    marginRight: TOC_DESKTOP_WIDTH,
  },
});
