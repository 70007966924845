import { last, pullAll, union } from 'lodash-es';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';

import { BaseModal, IconButton, LoadingIndicator, StyledText } from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { useDragScroll } from 'src/hooks/useDragScroll';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { type Props } from './PDFViewer';
import { PDFViewToolbar } from './PDFViewerToolbar';
import { usePDFFile } from '../hooks/queries';
import { useScale } from '../hooks/useScale';
import './pdf-viewer.css';

interface ContentProps extends Props {
  isFullScreen: boolean;
  toggleFullScreen(isFullScreen: boolean): void;
}

export const PDFViewer: React.FC<Props> = ({ pdfUrl, ...props }) => {
  const [isFullScreen, setFullScreen] = useState(false);

  const handleModalClosePress = () => {
    setFullScreen(false);
    props.onFullScreenClosePress?.();
  };

  return (
    <>
      <PDFViewerContent pdfUrl={pdfUrl} isFullScreen={false} toggleFullScreen={setFullScreen} {...props} />
      {isFullScreen && (
        <ModalPortal>
          <BaseModal
            padded={false}
            fitContents
            style={styles.modal}
            testID="algorithm-pdf-viewer-fullscreen"
          >
            <View style={styles.modalCloseButton}>
              <IconButton
                name="close"
                width={13}
                color={palette.white}
                onHoverColor={palette.white}
                onPress={handleModalClosePress}
                testID="modal-close-btn"
                accessibilityLabel="Close"
              />
            </View>
            <View style={styles.clickDetector}>
              <PDFViewerContent pdfUrl={pdfUrl} isFullScreen toggleFullScreen={setFullScreen} {...props} />
            </View>
          </BaseModal>
        </ModalPortal>
      )}
    </>
  );
};

const PDFViewerContent: React.FC<ContentProps> = ({
  pdfUrl,
  isFullScreen,
  toggleFullScreen,
  onScaleUpPress,
  onScaleDownPress,
  onNextPress,
  onPreviousPress,
  onFullScreenClosePress,
  onFullScreenPress,
}) => {
  const { data, isLoading, isError } = usePDFFile(pdfUrl);

  const [numOfPages, setNumOfPages] = useState(0);
  const {
    scale,
    isScaleDownDisabled,
    isScaleUpDisabled,
    onScaleDownPress: scaleDownHandler,
    onScaleUpPress: scaleUpHandler,
  } = useScale();
  const [height, setHeight] = useState(0);
  const [visiblePages, setVisiblePages] = useState<number[]>([]); // visible pages indexes

  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const pagesVisibilityObserverRef = useRef<IntersectionObserver | null>(null);

  useDragScroll(scrollWrapperRef.current);

  const detectPagesVisibility = () => {
    if (!scrollWrapperRef.current) return;
    pagesVisibilityObserverRef.current = new IntersectionObserver(
      (entries) => {
        const visible: number[] = [];
        const invisible: number[] = [];
        entries.forEach((element) => {
          const pageNumber = Number(element.target.attributes.getNamedItem(PAGE_DATA_ATTR)?.value || 0);
          const pageIndex = pageNumber - 1;
          if (element.isIntersecting) {
            visible.push(pageIndex);
          } else {
            invisible.push(pageIndex);
          }
        });
        setVisiblePages((state) => union(visible, pullAll(state, invisible)).sort());
      },
      {
        root: scrollWrapperRef.current,
        rootMargin: '-1px',
      },
    );
  };

  const handleDocumentLoad = (pdf: pdfjs.PDFDocumentProxy) => {
    const { numPages } = pdf;
    setNumOfPages(numPages);
    if (numPages > 1) {
      detectPagesVisibility();
    } else {
      // first page is always visible
      setVisiblePages([0]);
    }
  };

  const handlePageLoad = (page: pdfjs.PDFPageProxy) => {
    const { _pageIndex } = page;
    const pageElement = document.querySelector(getPageSelector(_pageIndex + 1));
    if (pageElement) {
      pagesVisibilityObserverRef.current?.observe(pageElement);
    }
  };

  const handlePreviousPress = () => {
    const lastVisiblePage = last(visiblePages);
    if (lastVisiblePage) {
      scrollToPage(lastVisiblePage - 1);
      onPreviousPress?.();
    }
  };

  const handleNextPress = () => {
    const firstVisiblePage = visiblePages[0];
    if (firstVisiblePage !== undefined && firstVisiblePage !== numOfPages - 1) {
      scrollToPage(firstVisiblePage + 1);
      onNextPress?.();
    }
  };

  const handleScaleDownPress = () => {
    scaleDownHandler();
    onScaleDownPress?.();
  };

  const handleScaleUpPress = () => {
    scaleUpHandler();
    onScaleUpPress?.();
  };

  const handleFullScreenPress = () => {
    if (isFullScreen) {
      onFullScreenClosePress?.();
    } else {
      onFullScreenPress?.();
    }
    toggleFullScreen(!isFullScreen);
  };

  const scrollToPage = (pageIndex: number) => {
    const pageElement = document.querySelector(getPageSelector(pageIndex + 1));
    const scrollElement = scrollWrapperRef.current;
    if (!pageElement || !scrollElement) return;

    scrollElement.scrollTo({
      left: (pageElement as HTMLDivElement).offsetLeft,
      behavior: 'smooth',
    });
  };

  useLayoutEffect(() => {
    if (!scrollWrapperRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const div = entries[0];
      setHeight(div.contentRect.height);
    });

    resizeObserver.observe(scrollWrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const file = useMemo(
    () => ({
      data,
    }),
    [data],
  );

  return (
    <View style={styles.viewerWrapper}>
      <PDFViewToolbar
        isFullScreen={isFullScreen}
        isPreviousDisabled={visiblePages.length < 2 && visiblePages[0] === 0}
        isNextDisabled={visiblePages.length < 2 && visiblePages[0] === numOfPages - 1}
        onFullScreenPress={handleFullScreenPress}
        onPreviousPress={handlePreviousPress}
        onNextPress={handleNextPress}
        onScaleDownPress={handleScaleDownPress}
        onScaleUpPress={handleScaleUpPress}
        allDisabled={!numOfPages}
        isScaleDownDisabled={isScaleDownDisabled}
        isScaleUpDisabled={isScaleUpDisabled}
      />
      <div
        style={StyleSheet.flatten([
          styles.documentWrapper,
          isFullScreen && styles.documentWrapperFullScreen,
        ])}
        ref={scrollWrapperRef}
      >
        {isError && <StyledText style={styles.errorMessage}>{i18n.t('pdfFileLoadError')}</StyledText>}
        {isLoading && <LoadingIndicator style={styles.loadingIndicator} />}
        {file.data && (
          <Document
            file={file}
            onLoadSuccess={handleDocumentLoad}
            className="pdf-document"
            loading={<LoadingIndicator style={styles.loadingIndicator} />}
          >
            {Array.from(new Array(numOfPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="pdf-page"
                height={height}
                scale={scale}
                onLoadSuccess={handlePageLoad}
              />
            ))}
          </Document>
        )}
      </div>
    </View>
  );
};

const PAGE_DATA_ATTR = 'data-page-number'; // data-attribute added to each page element

const getPageSelector = (pageNumber: number) => `[${PAGE_DATA_ATTR}="${pageNumber}"]`;

const styles = StyleSheet.create({
  loadingIndicator: {
    marginTop: 80,
  },
  errorMessage: {
    ...typography.body2,
    paddingHorizontal: 20,
  },
  modalCloseButton: {
    alignItems: 'flex-end',
    paddingBottom: 16,
  },
  modal: {
    backgroundColor: palette.transparent,
    width: '100%',
    height: '100vh',
  },
  clickDetector: {
    flex: 1,
  },
  documentWrapper: {
    backgroundColor: palette.white,
    overflow: 'scroll',
    height: 500,
    maxHeight: '70vh',
  },
  documentWrapperFullScreen: {
    height: '80vh',
    maxHeight: '80vh',
  },
  viewerWrapper: {
    borderWidth: 1,
    borderColor: palette.grey2,
    borderRadius: 5,
    overflow: 'hidden',
  },
});
