import { ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconButton } from 'src/components';
import { isWeb } from 'src/helpers';
import { palette } from 'src/styles';

interface Props {
  onFullScreenPress?(): void;
  isFullScreen?: boolean;
  onScaleDownPress?(): void;
  onScaleUpPress?(): void;
  onPreviousPress?(): void;
  onNextPress?(): void;
  isPreviousDisabled?: boolean;
  isNextDisabled?: boolean;
  isScaleDownDisabled?: boolean;
  isScaleUpDisabled?: boolean;
  allDisabled?: boolean;
}

const commonButtonProps: Partial<ComponentProps<typeof IconButton>> = {
  color: palette.navy,
  disabledColor: palette.grey5,
  backgroundColor: palette.grey2,
  containerSize: 28,
  width: 13,
};

export const PDFViewToolbar: React.FC<Props> = ({
  isFullScreen,
  isPreviousDisabled,
  isNextDisabled,
  isScaleDownDisabled,
  isScaleUpDisabled,
  allDisabled,
  onFullScreenPress,
  onScaleDownPress,
  onScaleUpPress,
  onNextPress,
  onPreviousPress,
}) => {
  return (
    <View style={styles.controlBar}>
      {isWeb && (
        <IconButton
          name={isFullScreen ? 'fullscreen-close' : 'fullscreen'}
          {...commonButtonProps}
          onPress={onFullScreenPress}
          disabled={allDisabled}
          testID="button-set-fullscreen"
          accessibilityLabel={isFullScreen ? 'Full Screen Close' : 'Full Screen'}
        />
      )}
      <View style={styles.buttonCluster}>
        <IconButton
          name="chevron-left"
          {...commonButtonProps}
          disabled={allDisabled || isPreviousDisabled}
          onPress={onPreviousPress}
          testID="button-previous-page"
          accessibilityLabel="Previous"
        />
        <IconButton
          name="chevron-right"
          {...commonButtonProps}
          containerStyle={styles.buttonClusterSpacing}
          disabled={allDisabled || isNextDisabled}
          onPress={onNextPress}
          testID="button-next-page"
          accessibilityLabel="Next"
        />
      </View>
      <View style={styles.buttonCluster}>
        <IconButton
          name="minus"
          {...commonButtonProps}
          disabled={allDisabled || isScaleDownDisabled || !onScaleDownPress}
          onPress={onScaleDownPress}
          testID="button-zoom-out"
          accessibilityLabel="Zoom Out"
        />
        <IconButton
          name="plus"
          {...commonButtonProps}
          containerStyle={styles.buttonClusterSpacing}
          disabled={allDisabled || isScaleUpDisabled || !onScaleUpPress}
          onPress={onScaleUpPress}
          testID="button-zoom-in"
          accessibilityLabel="Zoom In"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  controlBar: {
    height: 40,
    paddingHorizontal: 16,
    backgroundColor: palette.grey1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonCluster: {
    flexDirection: 'row',
  },
  buttonClusterSpacing: {
    marginLeft: 8,
  },
});
