import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Route, RouteProp } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks/useRoute';

import { useHandoutItem } from './queries';
import { handoutsSelector } from '../state';
import { SelectedHandout } from '../types';

export const useHandoutsToShare = () => {
  const { params } = useRoute<RouteProp<Route.ShareHandout>>();

  const { selectedHandouts, singleHandoutLanguage } = useSelector(handoutsSelector);
  const singleHandoutId = params?.handout;

  const { data: singleHandoutData, error, isLoading, fetchStatus } = useHandoutItem(singleHandoutId);
  const singleHandoutMode = !!singleHandoutId;

  const singleHandout: SelectedHandout | null = useMemo(() => {
    return singleHandoutData ? { ...singleHandoutData, language: singleHandoutLanguage } : null;
  }, [singleHandoutData, singleHandoutLanguage]);

  return {
    isLoading,
    fetchStatus,
    error,
    handouts: singleHandoutId ? (singleHandout ? [singleHandout] : []) : selectedHandouts,
    singleHandoutMode,
  };
};
