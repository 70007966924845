import * as icons from 'src/assets/icons';

export const iconComponents = {
  algorithm: icons.Algorithm,
  'arrow-right': icons.ArrowRight,
  'arrow-right-thin': icons.ArrowRightThin,
  'arrow-right-long': icons.ArrowRightLong,
  'arrow-up': icons.ArrowUp,
  'arrow-up-circle': icons.ArrowUpCircle,
  back: icons.Back,
  bold: icons.Bold,
  book: icons.Book,
  bulb: icons.Bulb,
  'caret-down': icons.CaretDown,
  checkmark: icons.Checkmark,
  'checkmark-circle': icons.CheckmarkCircle,
  'checkmark-circle-2': icons.CheckmarkCircle2,
  'chevron-down': icons.ChevronDown,
  'chevron-left': icons.ChevronLeft,
  'chevron-right': icons.ChevronRight,
  'chevron-right-circle': icons.ChevronRightCircle,
  'chevron-up': icons.ChevronUp,
  'clock-exclamation-mark': icons.ClockExclamationMark,
  close: icons.Close,
  'close-all': icons.CloseAll,
  'close-circle': icons.CloseCircle,
  'close-circle-small': icons.CloseCircleSmall,
  consensus: icons.Consensus,
  calculator: icons.Calculator,
  'calculator-thin': icons.CalculatorThin,
  dic: icons.DIC,
  documents: icons.Documents,
  'document-attachment': icons.DocumentAttachment,
  'document-plus': icons.DocumentPlus,
  'document-text': icons.Document,
  'document-rolled': icons.DocumentRolled,
  'dollar-checkmark': icons.DollarCheckmark,
  'dots-vertical': icons.DotsVertical,
  'drug-box': icons.DrugsBox,
  'drug-magnifier': icons.DrugMagnifier,
  'drug-monograph': icons.DrugMonograph,
  drugs: icons.Drugs,
  envelope: icons.Envelope,
  'error-404': icons.Error404,
  'exclamation-mark-circle': icons.ExclamationMarkCircle,
  expand: icons.Expand,
  eye: icons.Eye,
  'eye-off': icons.EyeOff,
  fullscreen: icons.Fullscreen,
  'fullscreen-close': icons.FullscreenClose,
  group: icons.Group,
  help: icons.Help,
  'help-center': icons.HelpCenter,
  'help-medium': icons.HelpMedium,
  'help-small': icons.HelpSmall,
  home: icons.Home,
  'indent-increase': icons.IndentIncrease,
  infectious: icons.Infectious,
  'info-circle': icons.InfoCircle,
  italic: icons.Italic,
  logout: icons.Logout,
  magnify: icons.Magnify,
  'magnify-menu': icons.MagnifyMenu,
  'magnify-text': icons.MagnifyText,
  menu: icons.Menu,
  microscope: icons.Microscope,
  minus: icons.Minus,
  moon: icons.Moon,
  note: icons.Note,
  oops: icons.Oops,
  'open-all': icons.OpenAll,
  options: icons.Options,
  pdf: icons.PDF,
  pencil: icons.Pencil,
  plus: icons.Plus,
  practice: icons.Practice,
  printer: icons.Printer,
  'printer-2': icons.Printer2,
  'printer-gradient': icons.PrinterGradient,
  reportable: icons.Reportable,
  'question-mark-circle': icons.QuestionMarkCircle,
  'sheet-remove-circle': icons.SheetRemove,
  star: icons.Star,
  'star-outline': icons.StarOutline,
  stethoscope: icons.Stethoscope,
  'stethoscope-text': icons.StethoscopeText,
  student: icons.Student,
  subscript: icons.Subscript,
  superscript: icons.Superscript,
  'table-of-contents': icons.TableOfContents,
  'text-star': icons.TextStar,
  'text-pencil': icons.TextPencil,
  tools: icons.Tools,
  'no-wifi': icons.NoWifi,
  delete: icons.Delete,
  edit: icons.Edit,
  padlock: icons.PadLock,
  masterCard: icons.MasterCard,
  american: icons.American,
  discover: icons.Discover,
  visa: icons.Visa,
  deviceComputer: icons.DeviceComputer,
  devicePhone: icons.DevicePhone,
  pause: icons.Pause,
  'checked-dot': icons.CheckedDot,
  'unchecked-dot': icons.UncheckedDot,
  underline: icons.Underline,
  user: icons.User,
  'user-medium': icons.UserMedium,
  'user-small': icons.UserSmall,
  zoonotic: icons.Zoonotic,
  'alert-triangle': icons.AlertTriangle,
  veterinarian: icons.Veterinarian,
  virus: icons.Virus,
  'medication-guide': icons.MedicationGuide,
  trash: icons.Trash,
};
