import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { CircleIconButton, InfoBox, ListItem, MobileContentHeader, StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { AppDispatch } from 'src/state/store';
import { CONTAINER_PADDING_H_MOBILE, typography } from 'src/styles';

import { HandoutLanguageRadioButton } from './HandoutLanguageRadioButton';
import { useHandoutsToShare } from '../hooks';
import { deselectHandout } from '../state';

interface Props {}

export const SelectedHandoutsList: React.FC<Props> = () => {
  const { t } = useTranslation('handouts');
  const { isTablet } = useDeviceInfo();
  const dispatch = useDispatch<AppDispatch>();

  const { handouts, singleHandoutMode } = useHandoutsToShare();

  const handleRemoveHandoutPress = (id: string) => {
    dispatch(deselectHandout(id));
  };

  const paddedOnMobileStyle = !isTablet && styles.paddedMobile;

  return (
    <View>
      <MobileContentHeader style={[styles.header, paddedOnMobileStyle]}>
        {t('handout', { count: handouts.length })}
      </MobileContentHeader>
      {handouts.map((item, index) => (
        <React.Fragment key={item.id}>
          <ListItem
            title={item.title}
            topBorder={index === 0}
            bottomBorder
            rightColumn={
              !singleHandoutMode && (
                <CircleIconButton
                  name="close"
                  outline
                  containerSize={28}
                  width={12}
                  onPress={() => handleRemoveHandoutPress(item.id)}
                  testID="remove-selected-handout-btn"
                />
              )
            }
            bottomSection={
              item.hasSpanishVersion ? <HandoutLanguageRadioButton handoutItem={item} /> : undefined
            }
            testID="selected-handout"
          />
        </React.Fragment>
      ))}
      {!handouts.length && (
        <Trans
          i18nKey="handouts:noSelectedHandouts"
          components={{
            p: (
              <StyledText
                testID="no-selected-handouts-info"
                style={[styles.infoText, paddedOnMobileStyle]}
              />
            ),
            b: <StyledText style={typography.weightBold} />,
          }}
        />
      )}
      {!singleHandoutMode && handouts.length === 1 && (
        <InfoBox style={[styles.selectMoreBox, paddedOnMobileStyle]} testID="one-selected-handout-info">
          <Trans
            i18nKey="handouts:selectMoreHandouts"
            components={{
              b: <StyledText style={typography.weightBold} />,
            }}
          />
        </InfoBox>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  paddedMobile: {
    marginHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  header: {
    marginBottom: 12,
  },
  infoText: {
    ...typography.body2,
  },
  selectMoreBox: {
    marginTop: 20,
  },
});
