import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { useUserInfo } from 'src/features/auth/hooks';
import { closeNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

import { StandalonePaymentDetailsForm } from './PaymentDetails/StandalonePaymentDetailsForm';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { PlanBenefits } from './PlanBenefits/PlanBenefits';
import { PlanDetails } from './PlanDetails/PlanDetails';
import { SubscriptionPurchaseForm } from './SubscriptionPurchaseForm/SubscriptionPurchaseForm';
import { useSubscriptionDetailsForm } from '../hooks/useSubscriptionDetailsForm';

export const SubscriptionDetails: React.FC = () => {
  const userInfo = useUserInfo();
  const { isLargeDesktop } = useDeviceInfo();
  const {
    formProps,
    closeForm,
    handleAddMoreUsers,
    handleReactivateSubscription,
    handleUpgradeToPro,
    isDataLoading,
    isFormOpen,
  } = useSubscriptionDetailsForm();

  useEffect(() => closeNotification, []);

  const itemStyle = [styles.item, !isLargeDesktop && styles.itemMobile];

  return (
    <View style={[styles.wrapper, !isLargeDesktop && styles.wrapperMobile]}>
      <View style={itemStyle}>
        <PlanDetails
          handleAddMoreUsers={handleAddMoreUsers}
          handleUpgradeToPro={() => handleUpgradeToPro('plan-type')}
          handleReactivateSubscription={handleReactivateSubscription}
          hideActionButtons={isFormOpen}
        >
          {isFormOpen && formProps && !isDataLoading && (
            <SubscriptionPurchaseForm
              data={formProps.data}
              buyingNewSubscription={formProps.buyingNewSubscription}
              close={closeForm}
            />
          )}
        </PlanDetails>
      </View>
      <View style={isLargeDesktop && styles.row}>
        <View style={[itemStyle, isLargeDesktop && styles.rowItemDesktop]}>
          <PlanBenefits
            handleUpgradeToPro={() => handleUpgradeToPro('plan-benefits')}
            handleReactivateSubscription={handleReactivateSubscription}
            hideActionButtons={isFormOpen}
          />
        </View>
        <View style={[itemStyle, isLargeDesktop && styles.rowItemDesktop]}>
          <PaymentHistory />
        </View>
      </View>
      {!userInfo.expiredSubscription && (
        <View style={itemStyle}>
          <StandalonePaymentDetailsForm />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    margin: -20,
  },
  wrapperMobile: {
    margin: -14,
  },
  item: {
    margin: 20,
  },
  itemMobile: {
    margin: 10,
  },
  row: {
    flexDirection: 'row',
  },
  rowItemDesktop: {
    flex: 1,
  },
});
