import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { RadioGroup } from 'src/components';
import { useDeviceInfo } from 'src/hooks';
import { AppDispatch } from 'src/state/store';

import { useHandoutsToShare } from '../hooks/useHandoutsToShare';
import { setSingleHandoutLanguage, setSelectedHandoutLanguage, handoutsSelector } from '../state';
import { HandoutsLanguageType, SelectedHandout } from '../types';

interface Props {
  handoutItem: SelectedHandout;
}

export const HandoutLanguageRadioButton = ({ handoutItem }: Props) => {
  const { t } = useTranslation('handouts');
  const { isMobile } = useDeviceInfo();
  const dispatch = useDispatch<AppDispatch>();

  const { singleHandoutLanguage } = useSelector(handoutsSelector);
  const { singleHandoutMode } = useHandoutsToShare();

  const languageValue = singleHandoutMode
    ? singleHandoutLanguage
    : handoutItem.language || HandoutsLanguageType.English;

  const handleOnHandoutLanguageChange = (value: string, handoutItem: SelectedHandout) => {
    const language = value as HandoutsLanguageType;
    const handoutWithLanguage: SelectedHandout = {
      ...handoutItem,
      language: language,
    };

    if (singleHandoutMode) {
      dispatch(setSingleHandoutLanguage(language));
    } else {
      dispatch(setSelectedHandoutLanguage(handoutWithLanguage));
    }
  };

  return (
    <RadioGroup
      items={[
        {
          title: t('handoutsLanguage.english'),
          value: HandoutsLanguageType.English,
          testID: 'english-handout-radio',
        },
        {
          title: t('handoutsLanguage.spanish'),
          value: HandoutsLanguageType.Spanish,
          testID: 'spanish-handout-radio',
        },
        {
          title: t('handoutsLanguage.englishAndSpanish'),
          value: HandoutsLanguageType.SpanishEnglish,
          testID: 'spanish-english-handout-radio',
        },
      ]}
      value={languageValue}
      style={[styles.radioGroupContainer, !isMobile && styles.radioGroupContainerMobile]}
      onChange={(value) => handleOnHandoutLanguageChange(value, handoutItem)}
      direction={!isMobile ? 'column' : 'row'}
    />
  );
};

const styles = StyleSheet.create({
  radioGroupContainer: {
    paddingTop: 10,
    paddingHorizontal: 20,
  },
  radioGroupContainerMobile: {
    paddingHorizontal: 10,
  },
});
