import { debounce } from 'debounce';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { isAndroid } from 'src/helpers';

import { PDF_ACTIONS_DELAY } from '../constants';

const SCALE_STEP = 0.5;
const MAX_SCALE = 5;
const MIN_SCALE = 1;

const reducer = (state: number, action: 'increase' | 'decrease'): number => {
  if (action === 'increase') {
    return state + SCALE_STEP;
  } else {
    return state - SCALE_STEP;
  }
};

export const useScale = () => {
  const [scale, dispatch] = useReducer(reducer, 1);
  const [isScaleUpDisabled, setIsScaleUpDisabled] = useState(false);
  const [isScaleDownDisabled, setIsScaleDownDisabled] = useState(false);

  useEffect(() => {
    setIsScaleUpDisabled(scale >= MAX_SCALE);
    setIsScaleDownDisabled(scale <= MIN_SCALE);
  }, [scale]);

  const onScaleUpPress = () => {
    if (!isScaleUpDisabled) {
      dispatch('increase');
    }
  };

  const onScaleDownPress = () => {
    if (!isScaleDownDisabled) {
      dispatch('decrease');
    }
  };
  /**
   * Added debounce to the scaling action to reduce as much as possible
   * the possibility of apllication crashes on Android
   * realted bug that occurs in Logcat: https://github.com/wonday/react-native-pdf/issues/847
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedScaleUpPress = useCallback(debounce(onScaleUpPress, PDF_ACTIONS_DELAY), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedScaleDownPress = useCallback(debounce(onScaleDownPress, PDF_ACTIONS_DELAY), []);

  return {
    scale,
    onScaleUpPress: isAndroid ? debouncedScaleUpPress : onScaleUpPress,
    onScaleDownPress: isAndroid ? debouncedScaleDownPress : onScaleDownPress,
    isScaleDownDisabled,
    isScaleUpDisabled,
  };
};
