import { useLayoutEffect, useState } from 'react';

import { SubscriptionFormSource } from 'src/features/adminDashboard';
import { useUserInfo } from 'src/features/auth/hooks';
import { NavigationProp, Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks/useNavigation';

import { usePromotion, useSubscriptionInfo } from './queries';
import { getBasePlanSetup, getPlanDetailsFromPromotion } from '../helpers';
import { BillingPeriod, Plan, PlanSetup, Role } from '../types';

export interface SubscriptionDetailsFormProps {
  data: PlanSetup;
  buyingNewSubscription: boolean;
}

export interface UseSubscriptionDetailsFormResult {
  closeForm(): void;
  handleAddMoreUsers(): void;
  handleReactivateSubscription(): void;
  handleUpgradeToPro(source: SubscriptionFormSource): void;
  formProps: SubscriptionDetailsFormProps | null;
  isFormOpen: boolean;
  isDataLoading: boolean;
}

export const useSubscriptionDetailsForm = (): UseSubscriptionDetailsFormResult => {
  const [formProps, setFormProps] = useState<SubscriptionDetailsFormProps | null>(null);

  const { data: promotion, isLoading: isPromotionLoading, params: promotionParams } = usePromotion();
  const { data: subscriptionData, isFetching: isSubscriptionInfoFetching } = useSubscriptionInfo({
    refetchOnMount: 'always',
  });

  const navigation = useNavigation<NavigationProp<Route.AdminDashboard>>();
  const userInfo = useUserInfo();

  const isDataLoading = isSubscriptionInfoFetching || (promotionParams ? isPromotionLoading : false);

  const closeForm = () => {
    setFormProps(null);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAddMoreUsers = () => {
    setFormProps({
      data: {
        ...subscriptionData!.planSetup,
        quantity: subscriptionData!.numberOfLicences + 1,
        discountCode: subscriptionData!.couponCode,
      },
      buyingNewSubscription: false,
    });
  };

  const handleUpgradeToPro = (source: SubscriptionFormSource) => {
    navigation.setParams({ formSource: source });
    setFormProps({
      data: subscriptionData?.promotion
        ? getPlanDetailsFromPromotion(subscriptionData.promotion)
        : {
            ...subscriptionData!.planSetup,
            quantity: subscriptionData!.numberOfLicences,
            discountCode: subscriptionData!.couponCode,
            plan: Plan.PRO,
          },
      buyingNewSubscription: false,
    });
  };

  const handleReactivateSubscription = () => {
    setFormProps({
      data: {
        quantity: subscriptionData?.numberOfLicences ?? 1,
        billingPeriod: subscriptionData?.planSetup.billingPeriod ?? BillingPeriod.ANNUAL,
        discountCode: subscriptionData?.couponCode ?? '',
        plan: subscriptionData?.planSetup.plan ?? Plan.NORMAL,
        role: subscriptionData?.planSetup.role ?? Role.VETERINARY,
      },
      buyingNewSubscription: true,
    });
  };

  useLayoutEffect(() => {
    if (promotion && !subscriptionData?.promotion && !isDataLoading) {
      const predefinedData: Partial<PlanSetup> = {};
      if (subscriptionData) {
        const { billingPeriod, plan } = getBasePlanSetup(subscriptionData.planCode);
        if (plan === Plan.PRO) {
          predefinedData.billingPeriod = billingPeriod;
        }
      }
      setFormProps({
        data: getPlanDetailsFromPromotion(promotion, predefinedData),
        buyingNewSubscription: userInfo.expiredSubscription,
      });
    }
  }, [promotion, subscriptionData, userInfo.expiredSubscription, isDataLoading]);

  const isFormOpen = !!formProps;

  return {
    closeForm,
    handleAddMoreUsers,
    handleReactivateSubscription,
    handleUpgradeToPro,
    formProps,
    isFormOpen,
    isDataLoading,
  };
};
