import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { SelectButton } from 'src/components';
import { FeatureButton } from 'src/components/FeatureButton';
import { featureButtonColors } from 'src/constants/content';
import { MixpanelSelectHandoutParams } from 'src/features/tracking';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { typography } from 'src/styles';

import { useSelectedHandout } from '../hooks/useSelectedHandout';
import { SelectedHandout } from '../types';

interface Props {
  data: Omit<SelectedHandout, 'language'>;
  mixpanelParams: MixpanelSelectHandoutParams;
}

export const ShareHandoutButtons: React.FC<Props> = ({ data, mixpanelParams }) => {
  const { isTablet } = useDeviceInfo();
  const { t } = useTranslation('handouts');

  const { isSelected, toggleSelected, handleSharePress, shareButtonTitle } = useSelectedHandout(
    data,
    mixpanelParams,
  );

  const buttonStyles = [styles.button, isTablet && styles.buttonTablet];

  return (
    <View style={[styles.buttons, isTablet && styles.buttonsTablet]}>
      <SelectButton
        title={t('common:select')}
        isSelected={isSelected}
        onPress={toggleSelected}
        height={50}
        containerStyle={buttonStyles}
        colors={featureButtonColors}
        titleStyle={styles.buttonText}
        innerContainerStyle={styles.selectButtonInner}
        titleWrapperStyle={styles.buttonTitleWrapper}
        checkboxSize={22}
        testID="select-handout-btn"
      />
      <View style={buttonStyles}>
        <FeatureButton
          text={shareButtonTitle}
          icon="chevron-right"
          onPress={handleSharePress}
          testID="share-handout-btn"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttons: {
    marginTop: 26,
  },
  buttonsTablet: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: -5,
  },
  button: {
    marginBottom: 10,
  },
  buttonTablet: {
    flex: 1,
    marginHorizontal: 5,
    marginBottom: 0,
  },
  selectButtonInner: {
    justifyContent: 'flex-start',
  },
  buttonTitleWrapper: {
    paddingHorizontal: 16,
  },
  buttonText: {
    ...typography.body3Bold,
  },
});
